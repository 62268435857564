<div class="aspark-container-padding">
  <div class="row" *ngIf="!hideChannelType">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'channel' | translate }}</mat-label>
      <mat-select
        (selectionChange)="onChannelTypeChange()"
        [(ngModel)]="selectedChannelType"
      >
        <mat-option
          *ngFor="let channelType of channelTypes"
          [value]="channelType.ident"
        >
          {{ channelType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="preview-container">
    <div [innerHTML]="htmlStr" *ngIf="htmlStr"></div>
    <!-- BEGIN: demo -->
    <span class="href-link color-primary"
          [routerLink]="EViewRoutes.demoQuestionnaireLandingPage + '/' + campaign.id"
       *ngIf="htmlStr && campaign && campaign.publicationType.ident === 'Event'"
    >
      Please click here for registration
    </span>
    <!-- END -->
  </div>
  <p *ngIf="!htmlStr">
    {{ 'loadingOoo' | translate }}
  </p>
</div>
