import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MonitoringCampaign,} from 'src/app/api/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {
  genBooleanColumn,
  genEnumColumn,
  genTextColumnWithAutoCompleteFilter
} from "../../../util/grid/grid-renderer.util";
import {MonitoringChartComponent} from "../../../shared/monitoring-chart/monitoring-chart.component";
import {HtmlService} from "../../../services/html.service";
import {clientNames, EventRowData, gridData, PieData} from "./monitoring-event-campaing-data";

const offsetWidth = -10;
const offsetHeight = -10;

const ADDITIONAL_CLIENTS = 1574; // Number of additional dummy clients for grid and charts (max 2000)
const ADDITIONAL_CONTACTED = 294; // Number of additional contacted clients (wo manually set ones)

@Component({
  selector: 'app-monitoring-event-campaign',
  templateUrl: './monitoring-event-campaign.component.html',
})
export class MonitoringEventCampaignComponent implements OnInit {
  @Input() monitoringCampaign: MonitoringCampaign;
  @ViewChild('contactedChart') contactedChart: MonitoringChartComponent;
  @ViewChild('viewedChart') viewedChart: MonitoringChartComponent;
  @ViewChild('participatingChart') participatingChart: MonitoringChartComponent;
  @ViewChild('outstandingChart') outstandingChart: MonitoringChartComponent;

  columnDefs: ColDef[] = [
    genTextColumnWithAutoCompleteFilter({
      field: 'clientName',
      headerName: this.translateService.instant('clientName'),
      autoCompleteParams: () => this.rowData.map((client) => client.clientName),
    }),
    {
      ...genBooleanColumn(
        'contacted',
        this.translateService.instant('demoContacted'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    {
      ...genBooleanColumn(
        'viewed',
        this.translateService.instant('viewed'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    genEnumColumn({
      field: 'isParticipating',
      headerName: this.translateService.instant('demoParticipating'),
      values: [
        'Yes',
        'No',
        'Pending'
      ],
    }),
    genEnumColumn({
      field: 'food',
      headerName: this.translateService.instant('demoFood'),
      values: [
        'Vegetarian',
        'Meat'
      ],
    }),
    {
      ...genEnumColumn({
        field: 'allergies',
        headerName: this.translateService.instant('demoAllergies'),
        values: [
          'Milk',
          'Peanuts',
          'Gluten',
        ]
      }),
      valueFormatter: null // use the ag-grid default formatter
    }
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => {
      this.gridApi = event.api;
    }
  };

  rowData: EventRowData[] = [];
  pieData: PieData;
  gridApi: GridApi;

  constructor(
    protected translateService: TranslateService,
    protected readonly htmlService: HtmlService,
  ) {
  }

  ngOnInit(): void {
    this.loadEventData();
    this.htmlService.waitFor(
      () => !!this.contactedChart && !!this.viewedChart && !!this.participatingChart && !!this.outstandingChart,
      () => this.updateCharts()
    );
  }

  private loadEventData() {
    const manualClients = gridData.manualClients.map((client) => ({
      ...client,
      isParticipating: client.isParticipating === true ? 'Yes' : client.isParticipating === false ? 'No' : 'Pending',
    }));
    const additionalContactedClients =
      clientNames.slice(0, Math.min(clientNames.length, ADDITIONAL_CONTACTED)).map((clientName) => ({
        clientName,
        contacted: true,
        viewed: false,
        isParticipating: 'Pending',
        food: null,
        allergies: null,
      }));
    // If ADDITIONAL_CONTACTED > clientsNames.length it will return an empty array
    const additionalDummyClients =
      clientNames.slice(ADDITIONAL_CONTACTED, Math.min(clientNames.length, ADDITIONAL_CLIENTS)).map((clientName) => ({
        clientName,
        contacted: false,
        viewed: false,
        isParticipating: 'Pending',
        food: null,
        allergies: null,
      }));
    this.rowData = manualClients.concat(additionalContactedClients).concat(additionalDummyClients);

    const contactedCount = this.rowData.filter((client) => client.contacted).length;
    const viewedCount = this.rowData.filter((client) => client.viewed).length;
    const participatingCount = this.rowData.filter((client) => client.isParticipating === 'Yes').length;
    const notParticipatingCount = this.rowData.filter((client) => client.isParticipating === 'No').length;
    const notPendingCount = participatingCount + notParticipatingCount;
    const totalCount = this.rowData.length;

    this.pieData = {
      contacted: [{name: 'Contacted', count: contactedCount}, {name: 'Not contacted', count: totalCount - contactedCount}],
      viewed: [{name: 'Viewed', count: viewedCount}, {name: 'Not viewed', count: totalCount - viewedCount}],
      participating: [{name: 'Yes', count: participatingCount}, {name: 'No', count: notParticipatingCount}],
      outstanding: [{name: 'Yes', count: totalCount - notPendingCount}, {name: 'No', count: notPendingCount}],
    }
  }

  private async updateCharts() {
    const chartContacted = this.htmlService.getElem('.chart-graph.contacted');
    const chartViewed = this.htmlService.getElem('.chart-graph.viewed');
    const chartParticipating = this.htmlService.getElem('.chart-graph.participating');
    const chartOutstanding = this.htmlService.getElem('.chart-graph.outstanding');
    const getTr = (key: string) => this.translateService.instant(key);
    this.contactedChart.setPieData(getTr('contacted'), this.pieData.contacted, {
      titleAlign: 'center',
      legendLayout: 'horizontal',
      legendAlign: 'center',
      legendVerticalAlign: 'bottom',
      width: chartContacted.clientWidth + offsetWidth,
      height: chartContacted.clientHeight + offsetHeight
    });
    this.viewedChart.setPieData(getTr('viewed'), this.pieData.viewed, {
      titleAlign: 'center',
      legendLayout: 'horizontal',
      legendAlign: 'center',
      legendVerticalAlign: 'bottom',
      width: chartViewed.clientWidth + offsetWidth,
      height: chartViewed.clientHeight + offsetHeight
    });
    this.participatingChart.setPieData(getTr('demoParticipating'), this.pieData.participating, {
      titleAlign: 'center',
      legendLayout: 'horizontal',
      legendAlign: 'center',
      legendVerticalAlign: 'bottom',
      width: chartParticipating.clientWidth + offsetWidth,
      height: chartParticipating.clientHeight + offsetHeight
    });
    this.outstandingChart.setPieData(getTr('demoOutstanding'), this.pieData.outstanding, {
      titleAlign: 'center',
      legendLayout: 'horizontal',
      legendAlign: 'center',
      legendVerticalAlign: 'bottom',
      width: chartOutstanding.clientWidth + offsetWidth,
      height: chartOutstanding.clientHeight + offsetHeight
    });
  }
}
