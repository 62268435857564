import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder,} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";
import {Campaign} from "../../api/core";
import {DataService} from "../../services/data.service";
import {filter, Subscription} from "rxjs";

type QuestionType = 'text' | 'singleOption' | 'multipleOption';

interface Question {
  id: number;
  type: QuestionType;
  body: string;
  options: QuestionOption[];
}

interface QuestionOption {
  id: number;
  body: string;
}

@Component({
  selector: 'app-demo-questionnaire-form',
  templateUrl: './demo-questionnaire-form.component.html',
})
export class DemoQuestionnaireFormComponent implements OnInit, OnDestroy {

  questions: Question[] = [];
  campaign: Campaign;
  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private translationService: TranslateService,
    private dataService: DataService,
  ) {
  }

  addTextQuestion() {
    this.questions.push({
      id: this.questions.length + 1,
      type: 'text',
      body: '',
      options: [],
    });
  }

  addSingleOptionQuestion() {
    this.questions.push({
      id: this.questions.length + 1,
      type: 'singleOption',
      body: '',
      options: [
        {id: 1, body: 'Option 1'},
        {id: 2, body: 'Option 2'},
      ],
    });
  }

  addMultipleOptionQuestion() {
    this.questions.push({
      id: this.questions.length + 1,
      type: 'multipleOption',
      body: '',
      options: [
        {id: 1, body: 'Multiple option 1'},
        {id: 2, body: 'Multiple option 2'},
      ],
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          this.questions.push({
            id: this.questions.length + 1,
            type: 'singleOption',
            body: 'Will you participate?',
            options: [
              {id: 1, body: 'Yes'},
              {id: 2, body: 'No'},
            ],
          });
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  deleteOption(q: Question, o: QuestionOption) {
    q.options = q.options.filter((option) => option.id !== o.id);
  }

  addOption(q: Question) {
    q.options.push({
      id: q.options.length + 1,
      body: 'Option ' + (q.options.length + 1),
    });
  }

  deleteQuestion(q: Question) {
    this.questions = this.questions.filter((question) => question.id !== q.id);
  }
}
