<div class="catalogue-container">
  <div class="chart-container" [class.collapsed]="isCollapsing">
    <div class="card-header">
      <div class="card-collapsible"  (click)="isCollapsing = !isCollapsing">
        <app-config-icon [iconIdentifier]="!isCollapsing ? 'expand_more' : 'navigate_next'"></app-config-icon>
        <div class="card-title">{{ 'charts' | translate }}</div>
      </div>
    </div>
    <div class="card-body chart-body">
      <div class="chart-row upper">
        <div class="chart-graph participation">
          <app-monitoring-chart #chart_participation></app-monitoring-chart>
          <dl class="rate h4">
            <dt>{{'mChartParticipationRate' | translate }}</dt>
            <dd class="h2">{{charts.participationRate | percent: '1.0-0'}}</dd>
          </dl>
        </div>
        <div class="chart-graph contacts">
          <app-monitoring-chart #chart_contacts></app-monitoring-chart>
          <dl class="rate h4">
            <dt>{{'mChartContactsRate' | translate }}</dt>
            <dd class="h2">{{charts.contactsRate | percent: '1.0-0'}}</dd>
          </dl>
          <dl class="avg h4">
            <dt>{{'mChartContactsRateAvg' | translate }}</dt>
            <dd class="h2">{{charts.contactsAvg | number: '1.0-0'}}</dd>
          </dl>
        </div>
        <div class="chart-graph view">
          <app-monitoring-chart #chart_view></app-monitoring-chart>
          <dl class="rate h4">
            <dt>{{'mChartViewRate' | translate }}</dt>
            <dd class="h2">{{charts.viewRate | percent: '1.0-0'}}</dd>
          </dl>
          <dl class="avg h4">
            <dt>{{'mChartViewRateAvg' | translate }}</dt>
            <dd class="h2">{{charts.viewedAvg | number: '1.0-0'}}</dd>
          </dl>
        </div>
        <div class="chart-graph execution">
          <app-monitoring-chart #chart_exec></app-monitoring-chart>
          <dl class="rate h4">
            <dt>{{'mChartExecutionRate' | translate }}</dt>
            <dd class="h2">{{charts.executionRate | percent: '1.0-0'}}</dd>
          </dl>
        </div>
      </div>
      <div class="chart-row lower">
        <swiper-container init="false">
          <swiper-slide>
            <div class="chart-page">
              <app-card class="chart-graph centralized-campaigns">
                <div card-header>
                  {{'mChartCentralizedCampaigns' | translate}}
                </div>
                <app-monitoring-chart card-body #chart_centralized></app-monitoring-chart>
                <dl card-body>
                  <dt class="h4">{{'mChartTotalCampaigns' | translate}}</dt>
                  <dd class="total h2">{{charts.centralizedCampaigns.total | number: '1.0-0'}}</dd>
                  <dd class="message h4">
                    {{charts.centralizedCampaigns.message}}
                  </dd>
                </dl>
              </app-card>
              <app-card class="chart-graph trade-volume h4">
                <div card-header>
                  {{this.labelBuilder.labelWithCurrency('mChartTradesVolume')}}
                </div>
                <app-monitoring-chart card-body #chart_trades></app-monitoring-chart>
                <dl class="total-volume" card-body>
                  <dt>{{'mChartTradesVolumeTotalVolume' | translate}}</dt>
                  <dd class="h2">{{ charts.tradesVolume.totalVolume | number: '1.0-0'}}</dd>
                </dl>
                <dl class="total-trades" card-body>
                  <dt>{{'mChartTradesVolumeTotalTrades' | translate}}</dt>
                  <dd class="h2">{{ charts.tradesVolume.totalTrades | number}}</dd>
                </dl>
                <dl class="buy-volume" card-body>
                  <dt>{{'mChartTradesVolumeBuyVolume' | translate}}</dt>
                  <dd>{{ charts.tradesVolume.buyVolume | number: '1.0-0'}}</dd>
                </dl>
                <dl class="sell-volume" card-body>
                  <dt>{{'mChartTradesVolumeSellVolume' | translate}}</dt>
                  <dd>{{ charts.tradesVolume.sellVolume | number: '1.0-0'}}</dd>
                </dl>
                <dl class="buy-trades" card-body>
                  <dt>{{'mChartTradesVolumeBuyTrades' | translate}}</dt>
                  <dd>{{ charts.tradesVolume.buyTrades | number}}</dd>
                </dl>
                <dl class="sell-trades" card-body>
                  <dt>{{'mChartTradesVolumeSellTrades' | translate}}</dt>
                  <dd>{{ charts.tradesVolume.sellTrades | number}}</dd>
                </dl>
              </app-card>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="chart-page">
              <app-card class="chart-graph lang">
                <div card-header>
                  {{'language' | translate}}
                </div>
                <app-monitoring-chart #chart_lang card-body></app-monitoring-chart>
              </app-card>
              <app-card class="chart-graph channel">
                <div card-header>
                  {{'channel' | translate}}
                </div>
                <app-monitoring-chart #chart_channel card-body></app-monitoring-chart>
              </app-card>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
  <div class="list-container" *ngIf="!updating" [class.collapsed]="!isCollapsing">
    <div class="card-header">
      <div class="card-collapsible"  (click)="isCollapsing = !isCollapsing">
        <app-config-icon [iconIdentifier]="isCollapsing ? 'expand_more' : 'navigate_next'"></app-config-icon>
        <div class="card-title">{{ 'campaigns' | translate }}</div>
      </div>
    </div>
    <div class="card-body grids-card">
      <div class="tab-group">
        <mat-button-toggle-group name="movements" [(ngModel)]="dataType">
          <mat-button-toggle [value]="'top'">
            {{ 'monitoringCampaignTop5' | translate }}
          </mat-button-toggle>
          <mat-button-toggle [value]="'best'">
            {{ 'monitoringCampaignBest' | translate }}
          </mat-button-toggle>
          <mat-button-toggle [value]="'all'">
            {{ 'monitoringCampaignAll' | translate }}
          </mat-button-toggle>
          <mat-button-toggle [value]="'event'">
            {{ 'monitoringCampaignEvent' | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <app-grid
        #grid_all
        tableId="monitoring-campaign"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        *ngIf="!updating && dataType === 'all'"
      ></app-grid>
      <app-grid
        #grid_best
        tableId="monitoring-campaign-best"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptionsBest"
        *ngIf="!updating && dataType === 'best'"
      ></app-grid>
      <app-grid
        #grid_top
        tableId="monitoring-campaign-top"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptionsTop"
        *ngIf="!updating && dataType === 'top'"
      ></app-grid>
      <app-grid
        #grid_event
        tableId="monitoring-campaign-event"
        [rowData]="rowDataEventCampaign"
        [columnDefs]="eventCampaignsColumnDefs"
        [gridOptions]="gridOptionsEvent"
        *ngIf="!updating && dataType === 'event'"
      ></app-grid>
    </div>
  </div>
  <app-progress-update
    progressName="monitoringUpdate"
    completedWaitIntervalMs="2000"
    [socketCtor]="getSocket"
    (onCompleted)="onUpdateCompleted()"
    *ngIf="updating"
    class="card-body"
  ></app-progress-update>
</div>
