import {NavigationElement} from 'src/app/models/sidenav_config.model';
import {EViewRoutes, EViewTitles} from 'src/app/util/enum';
import {EProtectedActions} from '../../app/util/protected-actions';

export const SIDENAV_CONFIG: NavigationElement[] = [
  {
    title: EViewTitles.stories,
    icon: 'menu_book',
    route: EViewRoutes.stories,
    active: true,
    showChildren: false,
    activeForRoutes: [EViewRoutes.storyOverview],
    allowedRoles: [EProtectedActions.viewStories],
  },
  {
    title: EViewTitles.campaigns,
    icon: 'campaign',
    route: EViewRoutes.campaigns,
    active: true,
    showChildren: false,
    activeForRoutes: [EViewRoutes.campaignOverview],
    allowedRoles: [EProtectedActions.viewCampaigns],
  },
  {
    title: EViewTitles.playground,
    icon: 'pie_chart',
    route: EViewRoutes.playground,
    active: true,
    showChildren: false,
    activeForRoutes: [],
    allowedRoles: [],
  },
  {
    title: EViewTitles.objects,
    icon: 'data_object',
    route: EViewRoutes.objects,
    active: true,
    showChildren: false,
    activeForRoutes: [
      EViewRoutes.clientOverview,
      EViewRoutes.employeeOverview,
      EViewRoutes.intermediaryOverview,
      EViewRoutes.portfolioOverview,
      EViewRoutes.productOverview,
      EViewRoutes.objects,
      EViewRoutes.objectsClients,
      EViewRoutes.objectsEmployees,
      EViewRoutes.objectsIntermediaries,
      EViewRoutes.objectsPortfolios,
      EViewRoutes.objectsProducts,
    ],
    allowedRoles: [
      EProtectedActions.viewObjects,
    ],
  },
  {
    title: EViewTitles.distributor,
    icon: 'legend_toggle',
    route: EViewRoutes.distributor,
    active: true,
    showChildren: false,
    activeForRoutes: [
      EViewRoutes.distributor,
      EViewRoutes.distributorClients,
      EViewRoutes.distributorClientOverview,
      EViewRoutes.distributorEmployees,
      EViewRoutes.distributorEmployeeOverview,
      EViewRoutes.distributorIntermediaries,
      EViewRoutes.distributorIntermediaryOverview,
      EViewRoutes.distributorPortfolios,
    ],
    allowedRoles: [
      EProtectedActions.distributor,
    ],
  },
  {
    title: EViewTitles.monitorings,
    icon: 'monitoring',
    route: EViewRoutes.monitorings,
    active: true,
    showChildren: false,
    activeForRoutes: [
      EViewRoutes.monitoringCampaign,
      EViewRoutes.monitoringCampaignOverview,
      EViewRoutes.monitoringRelationshipManager,
      EViewRoutes.monitoringEventOverview,
    ],
    allowedRoles: [
      EProtectedActions.viewMonitoring,
      EProtectedActions.decentralizedCampaignMonitoring,
    ],
  },
  {
    title: EViewTitles.mySettings,
    icon: 'manage_accounts',
    route: EViewRoutes.mySettings,
    active: true,
    showChildren: false,
    activeForRoutes: [
      EViewRoutes.mySettings,
      EViewRoutes.myIntermediaryCollections,
      EViewRoutes.myDynamicUsers,
      EViewRoutes.myFavoriteCurrencies,
    ],
    allowedRoles: [EProtectedActions.editCollection],
  },
];
