import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {first, Subscription} from 'rxjs';
import {ModalData, ModalSubComponent} from 'src/app/models/modal.model';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {Campaign, CodeTableEntry, ContentPreviewRequest, ContentService, ContentUserInput,} from '../../api/core';
import {CodeTableService} from '../../services/code-table.service';
import {ECodeTables, EModalType, EViewRoutes} from '../../util/enum';
import {ContentData} from "../../models/content.model";
import {ActionType} from "../../campaign/views/campaign-overview/campaign-actions-list-utils";
import {DataService} from "../../services/data.service";
import {ModalService} from "../../services/modal.service";

export interface ContentPreviewParams {
  id?: number;
  storyId?: number;
  campaignId?: number;
  actionId?: number;
  channelType: string;
  channelTypesToShow: string[];
  hideChannelType: boolean;
  isIntermediary: boolean;
}

/**
 * Component to content form
 */
@Component({
  selector: 'app-content-preview',
  templateUrl: './content-preview.component.html',
})
export class ContentPreviewComponent implements OnInit,OnDestroy , ModalSubComponent {
  htmlStr: SafeHtml;
  contentPreviewParams: ContentPreviewParams;
  channelTypes: CodeTableEntry[] = [];
  selectedChannelType: string;
  hideChannelType: boolean;
  contentUserInput: ContentUserInput = null;
  // BEGIN: demo
  campaign: Campaign;
  private subscriptions: Subscription[] = [];
  // END

  constructor(
    private codeTableService: CodeTableService,
    private contentService: ContentService,
    public dialogRef: MatDialogRef<ModalComponent>,
    private sanitizer: DomSanitizer,
    private dataService: DataService, //demo
    private modalService: ModalService, //demo
    @Inject(MAT_DIALOG_DATA)
    public data: { data: { contentPreviewParams: ContentPreviewParams } } | { data: ContentData }
  ) {
    let matData = data.data as { contentPreviewParams: ContentPreviewParams };
    if (!matData.contentPreviewParams) {
      let inputData = data.data as ContentData;
      this.contentPreviewParams = {
        campaignId: inputData.campaignId,
        actionId: inputData.parentId,
        channelType: inputData.channel?.type?.ident,
        channelTypesToShow: [inputData.channel?.type?.ident],
        isIntermediary: inputData.type === ActionType.IntermediateAction,
        hideChannelType: false,
      };
    } else {
      this.contentPreviewParams = {...matData.contentPreviewParams};
    }
    this.selectedChannelType = this.contentPreviewParams.channelType;
    this.hideChannelType = this.contentPreviewParams.hideChannelType ||
      this.contentPreviewParams.channelTypesToShow.length < 2 || // only one channel type -> hide selection
      this.contentPreviewParams.actionId != null || false; // for custom content channel type is set -> hide in preview
  }

  ngOnInit(): void {
    this.codeTableService.getCodeTable(ECodeTables.channelType).subscribe({
      next: (channelTypes) => {
        this.channelTypes = channelTypes.filter((ct) =>
          this.contentPreviewParams.channelTypesToShow.includes(ct.ident)
        );
        if (!this.selectedChannelType) {
          this.selectedChannelType = this.channelTypes[0].ident;
        }
        this.onChannelTypeChange();
      },
    });
    // BEGIN: demo
    this.subscriptions.push(
      this.dataService.campaign$.subscribe((campaign) => (this.campaign = campaign))
    );
    // END
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  modalAction(modalType: EModalType): void {}

  onChannelTypeChange() {
    if (
      this.contentPreviewParams &&
      this.channelTypes.length > 0 &&
      this.selectedChannelType
    ) {
      this.updateContentPreview();
    }
  }

  updateContentPreview(contentUserInput?: ContentUserInput) {
    if (contentUserInput) this.contentUserInput = contentUserInput;
    const channelType = this.selectedChannelType || this.channelTypes.shift();
    const request = {
      id: this.contentPreviewParams.id,
      storyId: this.contentPreviewParams.storyId,
      campaignId: this.contentPreviewParams.campaignId,
      actionId: this.contentPreviewParams.actionId,
      isIntermediary: this.contentPreviewParams.isIntermediary,
      channelType: channelType,
      contentUserInput: this.contentUserInput,
    } as ContentPreviewRequest;
    this.contentService
      .getContentsPreview(request)
      .pipe(first())
      .subscribe({
        next: (resp) => {
          this.htmlStr = this.sanitizer.bypassSecurityTrustHtml(resp.preview);
        },
      });
  }

  protected readonly EViewRoutes = EViewRoutes;
}
