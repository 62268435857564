<ng-container *ngIf="campaign && campaign.publicationType.ident === 'Event'">
  <mat-divider></mat-divider>
  <div class="row row-single demo-row demo-row-title">
    <div class="h2">Questionnaire</div>
  </div>
  <div class="row row-single demo-row">
    <mat-form-field>
      <mat-label>Event Description</mat-label>
      <textarea matInput type="text" [rows]="5"></textarea>
    </mat-form-field>
    <button mat-fab color="primary"
            matTooltip="Add question"
            [matMenuTriggerFor]="menu">
      <app-config-icon iconIdentifier="add"></app-config-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item class="mat-mdc-menu-item-button" (click)="addTextQuestion()">
        <app-config-icon iconIdentifier="edit_m"></app-config-icon>
        <span>Free-Text question</span>
      </button>
      <button mat-menu-item class="mat-mdc-menu-item-button" (click)="addSingleOptionQuestion()">
        <app-config-icon iconIdentifier="rule"></app-config-icon>
        <span>Single-Option question</span>
      </button>
      <button mat-menu-item class="mat-mdc-menu-item-button" (click)="addMultipleOptionQuestion()">
        <app-config-icon iconIdentifier="list"></app-config-icon>
        <span>Multiple-Option question</span>
      </button>
    </mat-menu>
  </div>
  @for (q of questions; track q.id) {
    <div class="row row-single demo-row" *ngIf="q.type === 'text'">
      <mat-form-field>
        <mat-label>{{ 'Question ' + q.id }}</mat-label>
        <input matInput type="text" [value]="q.body">
        <button mat-icon-button matSuffix color="primary" (click)="deleteQuestion(q)">
          <app-config-icon iconIdentifier="delete"></app-config-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="row row-double demo-row" *ngIf="q.type === 'singleOption'">
      <mat-form-field>
        <mat-label>{{ 'Question ' + q.id }}</mat-label>
        <input matInput type="text" [value]="q.body">
        <button mat-icon-button matSuffix color="primary" (click)="deleteQuestion(q)">
          <app-config-icon iconIdentifier="delete"></app-config-icon>
        </button>
      </mat-form-field>
      <div>
        <mat-radio-group>
          @for (o of q.options; track o.id) {
            <div class="row demo-row">
              <mat-radio-button color="primary"><input matInput type="text" [value]="o.body"></mat-radio-button>
              <button mat-icon-button (click)="deleteOption(q,o)">
                <app-config-icon iconIdentifier="delete"></app-config-icon>
              </button>
            </div>
          }
        </mat-radio-group>
        <button mat-icon-button color="primary" matTooltip="Add option" (click)="addOption(q)">
          <app-config-icon iconIdentifier="add"></app-config-icon>
        </button>
      </div>
    </div>
    <div class="row row-double demo-row" *ngIf="q.type === 'multipleOption'">
      <mat-form-field>
        <mat-label>{{ 'Question ' + q.id }}</mat-label>
        <input matInput type="text" [value]="q.body">
        <button mat-icon-button matSuffix color="primary" (click)="deleteQuestion(q)">
          <app-config-icon iconIdentifier="delete"></app-config-icon>
        </button>
      </mat-form-field>
      <div>
        <mat-radio-group>
          @for (o of q.options; track o.id) {
            <div class="row demo-row">
              <mat-checkbox color="primary"><input matInput type="text" [value]="o.body"></mat-checkbox>
              <button mat-icon-button (click)="deleteOption(q,o)">
                <app-config-icon iconIdentifier="delete"></app-config-icon>
              </button>
            </div>
          }
        </mat-radio-group>
        <button mat-icon-button color="primary" matTooltip="Add option" (click)="addOption(q)">
          <app-config-icon iconIdentifier="add"></app-config-icon>
        </button>
      </div>
    </div>
  }

</ng-container>
