import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// shared
import { AdditionalChartsCardComponent } from './additional-charts-card/additional-charts-card.component';
import { BgShapeComponent } from './bg-shape/bg-shape.component';
import { BlockValueComponent } from 'src/app/shared/block-value/block-value.component';
import { CardComponent } from './card/card.component';
import { ChartComponent } from './chart/chart.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ConfigIconComponent } from './config-icon/config-icon.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { ContentFormComponent } from './content-form/content-form.component';
import { ContentPreviewComponent } from './content-preview/content-preview.component';
import { DropdownSelectionBarComponent } from 'src/app/shared/grid/custom-header/dropdown-selection-bar/dropdown-selection-bar.component';
import { DynamicNestedMenuComponent } from './dynamic-nested-menu/dynamic-nested-menu.component';
import { EditProductsComponent } from './edit-products/edit-products.component';
import { FilterActiveTagsComponent } from './filter-active-tags/filter-active-tags.component';
import { FilterAddComponent } from './filter-add/filter-add.component';
import { FilterAssetComponent } from './filter/filter-asset/filter-asset.component';
import { FilterComponent } from './filter/filter.component';
import { FilterDetailsAdvancedComponent } from './filter-details/filter-details-advanced/filter-details-advanced.component';
import { FilterDetailsAssetClassComponent } from './filter-details/filter-details-asset-class/filter-details-asset-class.component';
import { FilterDetailsAssetComponent } from './filter-details/filter-details-asset/filter-details-asset.component';
import { FilterDetailsClientComponent } from './filter-details/filter-details-client/filter-details-client.component';
import { FilterDetailsComponent } from './filter-details/filter-details.component';
import { FilterDetailsCurrencyComponent } from './filter-details/filter-details-currency/filter-details-currency.component';
import { FilterDetailsOrgPositionsComponent } from 'src/app/shared/filter-details/filter-details-org-positions/filter-details-org-positions.component';
import { FilterDetailsPortfolioComponent } from './filter-details/filter-details-portfolio/filter-details-portfolio.component';
import { FilterDetailsPositionComponent } from './filter-details/filter-details-position/filter-details-position.component';
import { FilterDetailsRegionComponent } from './filter-details/filter-details-region/filter-details-region.component';
import { FilterDetailsSectorComponent } from './filter-details/filter-details-sector/filter-details-sector.component';
import { FilterRangeWeightComponent } from './filter/filter-range-weight/filter-range-weight.component';
import { FilterDetailsIntermediaryComponent } from './filter-details/filter-details-intermediary/filter-details-intermediary.component';
import { FilterDetailsOperatorButtonComponent } from './filter-details/filter-details-operator-button/filter-details-operator-button.component';
import { GridComponent } from './grid/grid.component';
import { GridFilterComponent } from './grid/filter/grid-filter/grid-filter.component';
import { GridFilterFormComponent } from './grid/filter/grid-filter-form/grid-filter-form.component';
import { GridFilterItemDateComponent } from './grid/filter/grid-filter-items/grid-filter-item-date/grid-filter-item-date.component';
import { GridFilterItemNumericComponent } from './grid/filter/grid-filter-items/grid-filter-item-numeric/grid-filter-item-numeric.component';
import { GridFilterItemSetComponent } from './grid/filter/grid-filter-items/grid-filter-item-set/grid-filter-item-set.component';
import { GridFilterItemTextComponent } from './grid/filter/grid-filter-items/grid-filter-item-text/grid-filter-item-text.component';
import { HelpComponent } from './help/help.component';
import { HelpContentComponent } from './help/help-content/help-content.component';
import { HelpNavigationComponent } from './help/help-navigation/help-navigation.component';
import { IntermediaryPortfoliosPopupComponent } from './intermediary-portfolios-popup/intermediary-portfolios-popup.component';
import { ModalComponent } from './modal/modal.component';
import { OverviewDetailItemComponent } from './overview-detail-item/overview-detail-item.component';
import { PageComponent } from './page/page.component';
import { PortfolioDetailsComponent } from './portfolio-details/portfolio-details.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { ProductsCardComponent } from './products-card/products-card.component';
import { ProgressCardComponent } from './progress-card/progress-card.component';
import { ProgressUpdateComponent } from 'src/app/shared/progress-update/progress-update.component';
import { RiskReturnChartCardComponent } from './risk-return-chart-card/risk-return-chart-card.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { StoryCardComponent } from './story-card/story-card.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { TabComponent } from './tab/tab.component';
import { TableSelectionBarComponent } from './grid/custom-header/table-selection-bar/table-selection-bar.component';
import { TabsPanelComponent } from './tabs-panel/tabs-panel.component';
import { TagComponent } from './tag/tag.component';
import { ToggleHotFilterComponent } from './grid/custom-filters/toggle-hot-filter/toggle-hot-filter.component';
import {ContentDownloadDialogComponent} from "./content-download-dialog/content-download-dialog.component";
import {ContentAttachmentsComponent} from "./content-attachments/content-attachments.component";

// Angular
import { AngularMaterialModule } from 'src/app/angular-material/angular-material.module';
import {MatSliderModule} from '@angular/material/slider';
// libraries
import { HighchartsChartModule } from 'highcharts-angular';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
// pipes
import {
  DecentralizedActionPipe,
  FormatDeltaPipe,
  FormatPercentagePipe,
  GetFilterLabelPipe,
  GetFormattedValuePipe,
  GetHealthCheckIconClassPipe,
  GetHealthCheckIconPipe,
  GetPdfOfFormPipe,
  GetPersonalCampaignStatusClassPipe,
  GetPersonalCampaignStatusPipe,
  GetPortfolioAdditionalCardActionIconPipe,
  GetTruncatedTextTooltipPipe,
  HasChildrenPipe,
  IconRendererIconClassPipe,
  IconRendererIconPipe,
  IsCampaignEditablePipe,
  IsCampaignFrozenPipe,
  IsCampaignLaunchedPipe,
  IsFilterCategoryActivePipe,
  IsFilterClientFilterActivePipe,
  IsFilterIntermediaryFilterActivePipe,
  IsFilterPortfolioFilterActivePipe,
  IsFilterPositionFilterActivePipe,
  IsFilterRangeWeightFilerActivePipe,
  IsRendererHiddenPipe,
  NestedMenuArrayFilterPipe,
  NumberLocalDeChPipe,
  PercentageColorClassPipe,
  PercentageTrendingIconPipe,
  ProtectedActionPipe,
  ProtectedRoutePipe,
  RateColorClassPipe,
  RendererTooltipPipe,
  SafePipe,
  SafeUrlPipe,
  ShowTableSelectionToolbarActionsPipe,
  SuitabilityColorClassPipe,
  SuitabilityIconPipe,
  ToBooleanPipe,
  ToDecimalPipe,
  ToFixed,
  ToRoundedPipe,
  TruncateHasDecimals,
  TruncateOnlySuffixPipe,
  TruncateValuePipe,
  ValueOrZeroPipe,
} from './shared.pipe';
// renderer
import { ActionLanguageRenderer } from './grid/cell-renderers/action-language.renderer';
import { CheckboxCellRenderer } from './grid/cell-renderers/checkbox.renderer';
import { CustomContentRenderer } from './grid/cell-renderers/custom-content.renderer';
import { DialogContentRenderer } from './grid/cell-renderers/dialog-content.renderer';
import { IconButtonRenderer } from './grid/cell-renderers/icon-button.renderer';
import { IconRenderer } from './grid/cell-renderers/icon.renderer';
import {
  SuitabilityFilterCellRenderer,
  SuitabilityRenderer,
} from './grid/cell-renderers/suitability.renderer';
import { IconLabelRenderer } from './grid/cell-renderers/icon-label.renderer';
import { OrgPositionUsersComponent } from './org-position-users/org-position-users.component';
import { OverviewAllowedUsersComponent } from './overview-allowed-users/overview-allowed-users.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ContentFormFieldsComponent } from './content-form-fields/content-form-fields.component';
import { ContentFormVideoComponent } from './content-form-video/content-form-video.component';
import { ViewedFilterCellRenderer } from './grid/cell-renderers/viewed.renderer';
import { ExternalLinksComponent } from './external-links/external-links.component';
import { ExternalLinkListComponent } from './external-link-list/external-link-list.component';
import { ActionStatusRenderer } from './grid/cell-renderers/action-status.renderer';
import { ToastNoficationComponent } from './toast-notification/toast-notification.component';
import { MatCardModule } from '@angular/material/card';
import { LinkCellRenderer } from './grid/cell-renderers/link.renderer';
import {CodeTableFilterCellRenderer} from "./grid/cell-renderers/code-table.renderer";

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import {FroalaEditorModule, FroalaViewModule} from "angular-froala-wysiwyg";
import {TabsPickerComponent} from "./tabs-picker/tabs-picker.component";
import {TabPickerToolbarComponent} from "./tab-picker-toolbar/tab-picker-toolbar.component";
import {MultiPurposeButtonComponent} from "./multi-purpose-button/multi-purpose-button.component";
import {MonitoringChartsCardComponent} from "./monitoring-charts-card/monitoring-charts-card.component";
import {MonitoringChartComponent} from "./monitoring-chart/monitoring-chart.component";
import {
  DemoFilterDetailsProductComponent
} from "./filter-details/demo-filter-details-product/demo-filter-details-product.component";
import {
  DemoFilterDetailsCampaignLinkComponent
} from "./filter-details/demo-filter-details-campaign-link/demo-filter-details-campaign-link.component";
import {
  DemoFilterDetailsMarketingLinkComponent
} from "./filter-details/demo-filter-details-marketing-link/demo-filter-details-marketing-link.component";
import {DemoQuestionnaireFormComponent} from "./demo-questionnaire-form/demo-questionnaire-form.component";
register();
/**
 * Shared Module.
 * Contains all shared components.
 */
const sharedModules = [
  AdditionalChartsCardComponent,
  BgShapeComponent,
  CardComponent,
  ChartComponent,
  ConfigIconComponent,
  ContentCardComponent,
  ContentFormComponent,
  ContentFormFieldsComponent,
  ContentFormVideoComponent,
  ContentPreviewComponent,
  ClientDetailsComponent,
  DemoFilterDetailsProductComponent,
  DemoFilterDetailsCampaignLinkComponent,
  DemoFilterDetailsMarketingLinkComponent,
  DynamicNestedMenuComponent,
  EditProductsComponent,
  ExternalLinksComponent,
  ExternalLinkListComponent,
  FilterComponent,
  FilterActiveTagsComponent,
  FilterAddComponent,
  FilterDetailsComponent,
  FilterDetailsAssetComponent,
  FilterAssetComponent,
  FilterDetailsAssetClassComponent,
  FilterDetailsClientComponent,
  FilterDetailsCurrencyComponent,
  FilterDetailsPortfolioComponent,
  FilterDetailsPositionComponent,
  FilterDetailsAdvancedComponent,
  FilterDetailsRegionComponent,
  FilterDetailsSectorComponent,
  FilterRangeWeightComponent,
  FilterDetailsIntermediaryComponent,
  FilterDetailsOperatorButtonComponent,
  GridComponent,
  GridFilterComponent,
  GridFilterFormComponent,
  GridFilterItemDateComponent,
  GridFilterItemNumericComponent,
  GridFilterItemSetComponent,
  GridFilterItemTextComponent,
  HelpComponent,
  HelpContentComponent,
  HelpNavigationComponent,
  IntermediaryPortfoliosPopupComponent,
  ModalComponent,
  OverviewDetailItemComponent,
  PageComponent,
  ProductCardComponent,
  ProductsCardComponent,
  ProgressCardComponent,
  PortfolioDetailsComponent,
  RiskReturnChartCardComponent,
  StatisticsComponent,
  StoryCardComponent,
  SubHeaderComponent,
  TableSelectionBarComponent,
  TabComponent,
  TabsPanelComponent,
  TagComponent,
  ToggleHotFilterComponent,
  OrgPositionUsersComponent,
  OverviewAllowedUsersComponent,
  RedirectComponent,
  DropdownSelectionBarComponent,
  FilterDetailsOrgPositionsComponent,
  ProgressUpdateComponent,
  BlockValueComponent,
  ToastNoficationComponent,
  ContentDownloadDialogComponent,
  ContentAttachmentsComponent,
  TabsPickerComponent,
  TabPickerToolbarComponent,
  MultiPurposeButtonComponent,
  MonitoringChartsCardComponent,
  MonitoringChartComponent,
  DemoQuestionnaireFormComponent,
];

const renderer: any[] = [
  ActionLanguageRenderer,
  ActionStatusRenderer,
  CheckboxCellRenderer,
  CustomContentRenderer,
  IconLabelRenderer,
  DialogContentRenderer,
  IconRenderer,
  SuitabilityFilterCellRenderer,
  SuitabilityRenderer,
  IconButtonRenderer,
  ViewedFilterCellRenderer,
  LinkCellRenderer,
  CodeTableFilterCellRenderer,
];

const libraries: any[] = [
  HighchartsChartModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  AgGridModule,
];

const pipes: any[] = [
  FormatDeltaPipe,
  FormatPercentagePipe,
  GetFilterLabelPipe,
  GetFormattedValuePipe,
  GetHealthCheckIconClassPipe,
  GetHealthCheckIconPipe,
  GetPdfOfFormPipe,
  GetPersonalCampaignStatusClassPipe,
  GetPersonalCampaignStatusPipe,
  GetPortfolioAdditionalCardActionIconPipe,
  GetTruncatedTextTooltipPipe,
  HasChildrenPipe,
  IconRendererIconClassPipe,
  IconRendererIconPipe,
  IsCampaignEditablePipe,
  IsCampaignFrozenPipe,
  IsCampaignLaunchedPipe,
  IsFilterCategoryActivePipe,
  IsFilterClientFilterActivePipe,
  IsFilterIntermediaryFilterActivePipe,
  IsFilterRangeWeightFilerActivePipe,
  IsFilterPortfolioFilterActivePipe,
  IsFilterPositionFilterActivePipe,
  IsRendererHiddenPipe,
  NestedMenuArrayFilterPipe,
  NumberLocalDeChPipe,
  PercentageColorClassPipe,
  PercentageTrendingIconPipe,
  ProtectedActionPipe,
  DecentralizedActionPipe,
  ProtectedRoutePipe,
  RateColorClassPipe,
  RendererTooltipPipe,
  SafePipe,
  SafeUrlPipe,
  ShowTableSelectionToolbarActionsPipe,
  SuitabilityColorClassPipe,
  SuitabilityIconPipe,
  ToDecimalPipe,
  ToBooleanPipe,
  ToFixed,
  ToRoundedPipe,
  TruncateHasDecimals,
  TruncateOnlySuffixPipe,
  TruncateValuePipe,
  ValueOrZeroPipe,
];

@NgModule({
  declarations: [sharedModules, renderer, pipes],
  imports: [
    CommonModule,
    AngularMaterialModule,
    RouterModule,
    libraries,
    MatCardModule,
    FroalaEditorModule,
    FroalaViewModule,
    MatSliderModule
  ],
  exports: [sharedModules, AngularMaterialModule, libraries, pipes],
  providers: [pipes],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor() {}
}
