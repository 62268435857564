import {MonitoringCharts} from "../api/core";
import {delay, of} from "rxjs";

export const monitoringDemoData: MonitoringCharts = {
  "channels": [
    {
      "name": "E-banking",
      "count": 57125
    },
    {
      "name": "E-mail",
      "count": 57125
    }
  ],
  "languages": [
    {
      "name": "German",
      "count": 57125
    },
    {
      "name": "English",
      "count": 32543
    }
  ],
  "buyTrades": [
    {
      "name": "Jan",
      "count": 994
    },
    {
      "name": "Feb",
      "count": 621
    },
    {
      "name": "Mar",
      "count": 435
    },
    {
      "name": "Apr",
      "count": 497
    },
    {
      "name": "May",
      "count": 435
    },
    {
      "name": "Jun",
      "count": 870
    },
    {
      "name": "Jul",
      "count": 249
    },
    {
      "name": "Aug",
      "count": 311
    },
    {
      "name": "Sep",
      "count": 373
    },
    {
      "name": "Oct",
      "count": 497
    },
    {
      "name": "Nov",
      "count": 559
    },
    {
      "name": "Dec",
      "count": 373
    }
  ],
  "sellTrades": [
    {
      "name": "Jan",
      "count": 418
    },
    {
      "name": "Feb",
      "count": 262
    },
    {
      "name": "Mar",
      "count": 183
    },
    {
      "name": "Apr",
      "count": 209
    },
    {
      "name": "May",
      "count": 183
    },
    {
      "name": "Jun",
      "count": 366
    },
    {
      "name": "Jul",
      "count": 105
    },
    {
      "name": "Aug",
      "count": 131
    },
    {
      "name": "Sep",
      "count": 157
    },
    {
      "name": "Oct",
      "count": 209
    },
    {
      "name": "Nov",
      "count": 235
    },
    {
      "name": "Dec",
      "count": 157
    }
  ],
  "buyVolume": [
    {
      "name": "Jan",
      "value": 24856000.0
    },
    {
      "name": "Feb",
      "value": 15535000.0
    },
    {
      "name": "Mar",
      "value": 10874500.0
    },
    {
      "name": "Apr",
      "value": 12428000.0
    },
    {
      "name": "May",
      "value": 10874500.0
    },
    {
      "name": "Jun",
      "value": 21749000.0
    },
    {
      "name": "Jul",
      "value": 6214000.0
    },
    {
      "name": "Aug",
      "value": 7767500.0
    },
    {
      "name": "Sep",
      "value": 9321000.0
    },
    {
      "name": "Oct",
      "value": 12428000.0
    },
    {
      "name": "Nov",
      "value": 13981500.0
    },
    {
      "name": "Dec",
      "value": 9321000.0
    }
  ],
  "sellVolume": [
    {
      "name": "Jan",
      "value": 10456000.0
    },
    {
      "name": "Feb",
      "value": 6535000.0
    },
    {
      "name": "Mar",
      "value": 4574500.0
    },
    {
      "name": "Apr",
      "value": 5228000.0
    },
    {
      "name": "May",
      "value": 4574500.0
    },
    {
      "name": "Jun",
      "value": 9149000.0
    },
    {
      "name": "Jul",
      "value": 2614000.0
    },
    {
      "name": "Aug",
      "value": 3267500.0
    },
    {
      "name": "Sep",
      "value": 3921000.0
    },
    {
      "name": "Oct",
      "value": 5228000.0
    },
    {
      "name": "Nov",
      "value": 5881500.0
    },
    {
      "name": "Dec",
      "value": 3921000.0
    }
  ],
  "dataPointSlices": [
    "01.Jan",
    "16.Jan",
    "01.Feb",
    "16.Feb",
    "01.Mar",
    "16.Mar",
    "01.Apr",
    "16.Apr",
    "01.May",
    "16.May",
    "01.Jun",
    "16.Jun",
    "01.Jul",
    "16.Jul",
    "01.Aug",
    "16.Aug",
    "01.Sep",
    "16.Sep",
    "01.Oct",
    "16.Oct",
    "01.Nov",
    "16.Nov",
    "01.Dec",
    "16.Dec"
  ],
  "participationRate": {
    "value": 0.68,
    "average": 4.0,
    "values": [
      0.72,
      0.69,
      0.67,
      0.71,
      0.7,
      0.72,
      0.69,
      0.64,
      0.72,
      0.83,
      0.72,
      0.63,
      0.5,
      0.45,
      0.47,
      0.5,
      0.69,
      0.74,
      0.7,
      0.73,
      0.7,
      0.72,
      0.9,
      0.85
    ]
  },
  "contactsRate": {
    "value": 0.42,
    "average": 7215.0,
    "values": [
      0.641,
      0.52,
      0.57,
      0.61,
      0.59,
      0.63,
      0.59,
      0.58,
      0.64,
      0.72,
      0.64,
      0.59,
      0.52,
      0.38,
      0.45,
      0.58,
      0.59,
      0.63,
      0.61,
      0.58,
      0.59,
      0.62,
      0.81,
      0.72
    ]
  },
  "viewRate": {
    "value": 0.58,
    "average": 27.0,
    "values": [
      0.45,
      0.42,
      0.36,
      0.38,
      0.37,
      0.35,
      0.38,
      0.39,
      0.36,
      0.48,
      0.45,
      0.36,
      0.38,
      0.37,
      0.35,
      0.38,
      0.39,
      0.36,
      0.35,
      0.38,
      0.39,
      0.36,
      0.45,
      0.56
    ]
  },
  "executionRate": {
    "value": 0.25,
    "average": 0.0,
    "values": [
      0.38,
      0.31,
      0.25,
      0.24,
      0.23,
      0.24,
      0.26,
      0.24,
      0.21,
      0.24,
      0.35,
      0.32,
      0.25,
      0.24,
      0.23,
      0.24,
      0.26,
      0.24,
      0.21,
      0.24,
      0.28,
      0.26,
      0.25,
      0.27
    ]
  },
  "centralizedCampaignTotal": 114,
  "centralizedCampaignAverage": 9.5,
  "centralizedCampaignCategories": [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  "centralizedCampaignData": [
    {
      "name": "Investment Idea",
      "values": [
        5.0,
        5.0,
        3.0,
        5.0,
        5.0,
        6.0,
        4.0,
        2.0,
        3.0,
        4.0,
        5.0,
        4.0
      ]
    },
    {
      "name": "Economic Insights",
      "values": [
        0.0,
        0.0,
        0.0,
        0.0,
        1.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        1.0
      ]
    },
    {
      "name": "Switch",
      "values": [
        4.0,
        5.0,
        5.0,
        8.0,
        7.0,
        2.0,
        4.0,
        2.0,
        2.0,
        8.0,
        7.0,
        5.0
      ]
    }
  ]
};

export function getDemoCampaignCharts() {
  return of(monitoringDemoData).pipe(delay(100));
}
