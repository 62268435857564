<div class="event-campaign-container">
  <div class="grid-container">
    <app-grid
      tableId="monitoring-event-campaign-overview"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
    ></app-grid>
  </div>
  <div class="charts-container">
    <app-card [showHeader]="false">
      <div card-body class="chart-graph contacted">
        <app-monitoring-chart #contactedChart></app-monitoring-chart>
      </div>
    </app-card>
    <app-card [showHeader]="false">
      <div card-body class="chart-graph viewed">
        <app-monitoring-chart #viewedChart></app-monitoring-chart>
      </div>
    </app-card>
    <app-card [showHeader]="false">
      <div card-body class="chart-graph outstanding">
        <app-monitoring-chart #outstandingChart></app-monitoring-chart>
      </div>
    </app-card>
    <app-card [showHeader]="false">
      <div card-body class="chart-graph participating">
        <app-monitoring-chart #participatingChart></app-monitoring-chart>
      </div>
    </app-card>
  </div>
</div>
